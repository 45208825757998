import React, { useState, Fragment } from "react";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";

import ChartDataLabels from "chartjs-plugin-datalabels";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSeedling,
  faSmog,
  faMoneyBillTrendUp,
  faFaucetDrip,
  faPeopleGroup,
  faArrowTrendUp,
  faArrowTrendDown,
  faCoins,
  faFaceSmileBeam,
  faFaceSmile,
} from "@fortawesome/free-solid-svg-icons";

import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

Chart.register(ChartDataLabels);

const Card = ({
  decision,
  selected,
  handleSelect,
  initialShortTermNeeds,
  round,
  showAdditionalScore,
}) => {
  const [hover, setHover] = useState(false);

  const barChartOptions = {
    responsive: true,
    scales: {
      y: {
        stacked: true,
        title: {
          display: true,
          text: "Économie d'eau",
        },
        max: 12,
      },
    },
    plugins: {
      datalabels: {
        anchor: "start",
        offset: -5,
        align: "top",
        formatter: Math.round,
        font: {
          weight: "bold",
          color: "black",
          size: 14,
        },
      },
      legend: {
        display: false,
      },
    },
  };
  const data = {
    labels: ["Tour 1", "Tour 2", "Tour 3", "Tour 4", "Tour 5"],
    datasets: [
      {
        label: "Economies d'eau réalisées",
        backgroundColor: "#54a8ba",
        data: decision.water_saving,
      },
    ],
  };

  const selectDecision = () => {
    if (!hover) {
      handleSelect(decision);
    }
  };

  const showDetails = (e) => {
    e.stopPropagation();
    setHover(true);
  };

  return (
    <div className="card">
      <div
        className={selected ? "card-content card-selected" : "card-content"}
        style={{
          backgroundImage: hover ? "" : `url(${decision.image_url})`,
          backgroundColor: hover ? "var(--hydros-very-light-blue)" : "black",
        }}
        onClick={selectDecision}
      >
        {hover ? (
          <div onClick={() => setHover(false)}>
            <div className="card-title-container">
              <h3 className="card-title-back">{decision.title}</h3>
            </div>
            <div className="card-bottom-back">
              {round > 0 && showAdditionalScore && (
                <div className="card-indicators">
                  {Object.keys(decision.decision_metrics)
                    .filter((key) => decision.decision_metrics[key] !== 0)
                    .map((key) => {
                      return (
                        <Fragment>
                          <Tooltip id="indicator-tooltip" />
                          <div
                            className="card-indicator"
                            data-tooltip-id="indicator-tooltip"
                            data-tooltip-content={
                              key === "biodiversity"
                                ? "Biodiversité"
                                : key === "carbon_economy"
                                ? "Sobriété énergétique"
                                : key === "economy"
                                ? "Santé économique"
                                : key === "popularity"
                                ? "Popularité"
                                : "Qualité de l'eau"
                            }
                          >
                            {decision.decision_metrics[key] > 0 ? (
                              <FontAwesomeIcon
                                className="icon"
                                style={{
                                  color: "green",
                                }}
                                icon={faArrowTrendUp}
                              />
                            ) : (
                              <FontAwesomeIcon
                                className="icon"
                                style={{
                                  color: "red",
                                }}
                                icon={faArrowTrendDown}
                              />
                            )}
                            {key === "biodiversity" ? (
                              <FontAwesomeIcon
                                className="icon"
                                icon={faSeedling}
                              />
                            ) : key === "carbon_economy" ? (
                              <FontAwesomeIcon className="icon" icon={faSmog} />
                            ) : key === "economy" ? (
                              <FontAwesomeIcon
                                className="icon"
                                icon={faCoins}
                              />
                            ) : key === "popularity" ? (
                              <FontAwesomeIcon
                                className="icon"
                                icon={faFaceSmile}
                              />
                            ) : (
                              <FontAwesomeIcon
                                className="icon"
                                icon={faFaucetDrip}
                              />
                            )}
                          </div>
                        </Fragment>
                      );
                    })}
                </div>
              )}
              <p className="card-cost card-cost-top">
                {decision.cost}
                <img className="currency" src="/vote-black4.png" />
              </p>

              {initialShortTermNeeds > 0 && (
                <p className="water-budget">
                  {decision.water_saving[0]}
                  <img
                    className="water-budget-drop-small"
                    src="/hydros_logo.png"
                    alt="water drop"
                  />
                </p>
              )}
            </div>

            <p className="card-description">{decision.short_description}</p>
            <div className="bar-content">
              <Bar data={data} options={barChartOptions} />
            </div>
          </div>
        ) : (
          <Fragment>
            {selected && (
              <div className="overlay">
                <img src="/green_check.png" className="overlay-check" />
              </div>
            )}
            <div className="card-info">
              <h3 className="card-title">{decision.title}</h3>
              <div className="card-bottom">
                <div className="card-details-container">
                  <button className="details-button" onClick={showDetails}>
                    +
                  </button>
                  {initialShortTermNeeds > 0 && (
                    <p className="water-budget">
                      {decision.water_saving[0]}
                      <img
                        className="water-budget-drop-small"
                        src="/hydros_logo.png"
                        alt="water drop"
                      />
                    </p>
                  )}
                </div>
                {round > 0 && showAdditionalScore && (
                  <div className="card-indicators">
                    {Object.keys(decision.decision_metrics)
                      .filter((key) => decision.decision_metrics[key] !== 0)
                      .map((key) => {
                        return (
                          <Fragment>
                            <Tooltip id="indicator-tooltip" />
                            <div
                              className="card-indicator"
                              data-tooltip-id="indicator-tooltip"
                              data-tooltip-content={
                                key === "biodiversity"
                                  ? "Biodiversité"
                                  : key === "carbon_economy"
                                  ? "Sobriété énergétique"
                                  : key === "economy"
                                  ? "Santé économique"
                                  : key === "popularity"
                                  ? "Popularité"
                                  : "Qualité de l'eau"
                              }
                            >
                              {decision.decision_metrics[key] > 0 ? (
                                <FontAwesomeIcon
                                  className="icon"
                                  style={{
                                    color: "green",
                                  }}
                                  icon={faArrowTrendUp}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="icon"
                                  style={{
                                    color: "red",
                                  }}
                                  icon={faArrowTrendDown}
                                />
                              )}
                              {key === "biodiversity" ? (
                                <FontAwesomeIcon
                                  className="icon"
                                  icon={faSeedling}
                                />
                              ) : key === "carbon_economy" ? (
                                <FontAwesomeIcon
                                  className="icon"
                                  icon={faSmog}
                                />
                              ) : key === "economy" ? (
                                <FontAwesomeIcon
                                  className="icon"
                                  icon={faCoins}
                                />
                              ) : key === "popularity" ? (
                                <FontAwesomeIcon
                                  className="icon"
                                  icon={faFaceSmile}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="icon"
                                  icon={faFaucetDrip}
                                />
                              )}
                            </div>
                          </Fragment>
                        );
                      })}
                  </div>
                )}

                <p className="card-cost">
                  {decision.cost}
                  <img className="currency" src="/vote-white4.png" />
                </p>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default Card;
