import React, { Fragment, useEffect, useRef, useState } from "react";
import { Bar, Pie } from "react-chartjs-2";
import Chart from "chart.js/auto";
import Card from "./Card";
import RadarChart from "./RadarChart";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import Context from "./Context"; // Assurez-vous de spécifier le bon chemin d'accès
import QRCode from 'qrcode.react';


const GraphGame = ({
  data,
  decision_metrics_data,
  goBackToGame,
  status,
  shortTermNeeds,
  showAdditionalScore,
}) => {
  const listSectors = ["agriculture", "industry", "public", "consumer"];

  const { gameId } = useParams();
  const navigate = useNavigate();

  // Extraire les données de chaque catégorie
  const [selectedPart, setSelectedPart] = useState(null);
  const [selectedDecision, setSelectedDecision] = useState(null);

  const handleClick = (Part, Sector) => {
    setSelectedPart({ Part, Sector });
    setSelectedDecision(null);
  };

  const handleClickPieChart = (decision) => {
    setSelectedDecision(decision);
  };

  const finishGame = async () => {
    
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({}),
    };
    await fetch(
      `${process.env.REACT_APP_API_URL}/game/${gameId}/finish_game`,
      options
    );

    window.location.reload();
  };

  const goToSummary = () => {
    navigate(`/game/${gameId}/summary`);
  }

  const finishBarChartOptions = {
    onClick: (e, element) => {
      if (element.length > 0) {
        const { datasetIndex: sectorIndex, index: tourIndex } = element[0];
        const Part = data.filter((item) => item.tour === tourIndex)[0];
        const Sector = listSectors[sectorIndex];
        handleClick(Part, Sector);
      }
    },
    plugins: {
      datalabels: false,
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: "Tours",
        },
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: "Économie d'eau",
        },
      },
    },
  };

  const finishBarChartData = status.finishGraph
    ? {
        labels: status.finishGraph.map((item) => item.tour + 1),
        datasets: [
          {
            label: "Agriculture",
            backgroundColor: Array(10)
              .fill("")
              .map((_, i) =>
                status.roundNumber > i ? "#b7e0e1" : "rgba(183,224,225,0.5)"
              ),
            data: status.finishGraph.map((item) => item.agriculture.total),
          },
          {
            label: "Industrie",
            backgroundColor: Array(10)
              .fill("")
              .map((_, i) =>
                status.roundNumber > i ? "#205673" : "rgba(32,86,115,0.5)"
              ),
            data: status.finishGraph.map((item) => item.industry.total),
          },
          {
            label: "Public",
            backgroundColor: Array(10)
              .fill("")
              .map((_, i) =>
                status.roundNumber > i ? "#db6c52" : "rgba(219,108,82,0.5)"
              ),
            data: status.finishGraph.map((item) => item.public.total),
          },
          {
            label: "Consommateurs",
            backgroundColor: Array(10)
              .fill("")
              .map((_, i) =>
                status.roundNumber > i ? "#fdedc4" : "rgba(253,237,196,0.5)"
              ),
            data: status.finishGraph.map((item) => item.consumer.total),
          },
          {
            label: "Courbe d'objectif",
            data: [
              { x: 1, y: (20 * Math.min(status.roundNumber, 1)) / 5 },
              { x: 2, y: (40 * Math.min(status.roundNumber, 2)) / 5 },
              { x: 3, y: (60 * Math.min(status.roundNumber, 3)) / 5 },
              { x: 4, y: (80 * Math.min(status.roundNumber, 4)) / 5 },
              {
                x: 5,
                y: Math.round((100 * Math.min(status.roundNumber, 5)) / 6),
              },
              {
                x: 6,
                y: Math.round((100 * Math.min(status.roundNumber, 5)) / 5.5),
              },
              {
                x: 7,
                y: Math.round((100 * Math.min(status.roundNumber, 5)) / 5.3),
              },
              {
                x: 8,
                y: Math.round((100 * Math.min(status.roundNumber, 5)) / 5.2),
              },
              {
                x: 9,
                y: Math.round((100 * Math.min(status.roundNumber, 5)) / 5.1),
              },
              {
                x: 10,
                y: Math.round((100 * Math.min(status.roundNumber, 5)) / 5),
              },
            ],
            type: "line",
            fill: "false",
            pointStyle: "circle",
          },
        ],
      }
    : null;

  const selectedBar = selectedPart ? selectedPart.Part : null;
  const listSelectedDecision = selectedBar
    ? selectedBar[selectedPart.Sector].decision_list
    : [];
  const pieChartData = {
    labels: listSelectedDecision.map((item) => item.title),
    datasets: [
      {
        data: listSelectedDecision.map((item) => item.contribution),
        backgroundColor: [
          "rgba(255, 99, 132, 0.8)",
          "rgba(54, 162, 235, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(210, 99, 132, 0.8)",
          "rgba(54, 190, 145, 0.8)",
          "rgba(29, 206, 106, 0.8)",
          // Add more colors as needed
        ],
      },
    ],
  };

  const pieChartOptions = {
    onClick: (e, element) => {
      if (element.length > 0) {
        const decisionIndex = element[0].index;
        const decision = listSelectedDecision[decisionIndex];
        handleClickPieChart(decision);
      }
    },
    plugins: {
      datalabels: false,
    },
  };

  const handleDownload = async () => {
    try {

      const response = await fetch(`${process.env.REACT_APP_API_URL}/game/${gameId}/download_report`);

      if (response.ok) {
        // La réponse est OK, alors traitons la réponse comme nécessaire
        // Dans cet exemple, nous allons simplement rediriger l'utilisateur vers le rapport téléchargé
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `game_report_${gameId}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

      } else {
        console.error('Erreur lors du téléchargement du rapport.');

      }
    } catch (error) {
      console.error('Erreur lors du téléchargement du rapport :', error);

    }
  };

  return (
    <div className="vertical-container">
      <div className="recap-container">
        <div className="recap-text-container">
          {status.roundNumber === 5 ? (
            <h3 className="recap-text no-bold">
              C'est la fin de votre dernier tour !{" "}
              {status.waterBudget <= 0 ? "Félicitations ! " : ""} Vous avez
              atteint <b>{100 - status.prevision}%</b> de votre objectif. Voici
              le récapitulatif des économies d'eau que vous avez réalisées au
              cours du temps :{" "}
            </h3>
          ) : (
            <h3 className="recap-text no-bold">
              Bonjour à toutes et tous, nous sommes en{" "}
              <b>{2024 + status.roundNumber}</b> bienvenue au{" "}
              <b>{status.roundNumber + 1}</b>
              ème Congrès sur l'eau.
              {status.shortTermNeeds <= 0 && (
                <Fragment>
                  <br />
                  <br />
                  Les objectifs à horizon 10 ans sont en{" "}
                  <b>{status.objectivesStatus}</b>, cette année on prévoit des
                  sécheresses <b>{status.droughtType}</b>, il faudra axer nos
                  décisions sur des mesures <b>{status.measuresTerm}</b>.
                </Fragment>
              )}
              {status.shortTermNeeds > 0 && (
                <Fragment>
                  <br />
                  <br />
                  Cette année les niveaux de précipitation ont été
                  particulièrement faibles, certains cours d'eau sont bientôt à
                  sec et les nappes phréatiques sont sous grande tension. Nous
                  faisons face à une <b>sécheresse</b>.
                  <br />
                  <br />
                  <img
                    src="https://res.cloudinary.com/dc1felxem/image/upload/v1686062229/meteo_qcrnpx.png"
                    className="meteo"
                  />
                  <br /> <br />
                  Les approvisionnements en eau de la population sont menacés,
                  vous allez donc devoir prendre des mesures afin de satisfaire
                  à <b>court terme</b> les besoins en eau.Vous devez économiser{" "}
                  <b>{status.shortTermNeeds}</b>
                  <span className="inline-icon">
                    <img
                      className="water-budget-drop-small"
                      src="/hydros_logo.png"
                      alt="water drop"
                    />
                  </span>{" "}
                  lors du prochain tour.
                </Fragment>
              )}
            </h3>
          )}
        </div>
        
        {status.roundNumber !== 5 && (
          <div className="context-container">
            <Context status={status} />
          </div>
        )}
        <div className="evolution-chart-container">
          <div className="chart-title chart-title-position">
            Evolution des économies d'eau
          </div>
          <div className="evolution-chart">
            {" "}
            <div className="chart-display">
              <Bar data={finishBarChartData} options={finishBarChartOptions} />
            </div>
          </div>
        </div>
        {showAdditionalScore && (
          <div className="grid-radar-container">
            <div className="radar-container">
              <div className="chart-title">Autres Indicateurs</div>
              <RadarChart data_radar={decision_metrics_data}></RadarChart>
            </div>
          </div>
        )}
        <div className="chart-details">
          {selectedPart && (
            <div className="chart-display">
              <Pie data={pieChartData} options={pieChartOptions} />
            </div>
          )}
        </div>
        <div className="chart-details">
          {selectedDecision && (
            <div>
              <Card
                decision={selectedDecision}
                selected={false}
                handleSelect={() => {}}
              />
            </div>
          )}
        </div>
      </div>

      {status.isFinished ?(
      <div className="qrcode-container">
        <div className="text-intro-title">Découvrez à quoi ressemble votre territoire !</div>
        <div>
          <QRCode value={"https://app.compte-gouttes.fr/game/"+gameId+"/final_player_form"} size={300}/>
        </div>
        <div>
          <button onClick={handleDownload} className='blue-button'>Télécharger le PDF</button>
        </div>
      </div>) : (<Fragment></Fragment>)}

      {status.roundNumber === 5 ? (
       
        status.isFinished ? (
          <Fragment>
            <button
                class="blue-button"
                style={{ marginTop: 15 }}
                onClick={goToSummary}
              >
                Bilan de partie
              </button>
          </Fragment>
        ) : (
          <Fragment>
            {(
              <button
                class="blue-button"
                style={{ marginTop: 15 }}
                onClick={finishGame}
              >
                Accéder au bilan de partie
              </button>
            )}
          </Fragment>
        )
      ) : (
        <div className="back-to-game" onClick={goBackToGame}>
          Revenons sur les événements marquants de l'an passé
          <FontAwesomeIcon icon={faArrowRightLong} />
        </div>
      )}
    </div>
  );
};

export default GraphGame;
