import React, { useState, useRef, useEffect } from 'react';

const CustomSelect = ({ options, onSelect, defaultValue }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultValue || null);
  const dropdownRef = useRef(null);

  const handleToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSelectOption = (option) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);
    onSelect(option);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="custom-select" ref={dropdownRef}>
      <button onClick={handleToggleDropdown} className="select-button">
        {selectedOption ? selectedOption.label : 'Select an option'}
      </button>
      {isDropdownOpen && (
        <ul className="options">
          {options.map((option) => (
            <li
              key={option.value}
              onClick={() => handleSelectOption(option)}
              className={`option ${selectedOption === option ? 'active' : ''}`}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomSelect;
