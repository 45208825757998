import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";

function Login(props) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [badLogin, setBadLogin] = useState(false);
  const [loginForm, setloginForm] = useState({
    email: "",
    password: "",
  });

  function logMeIn(event) {
    setBadLogin(false);
    fetch(`${process.env.REACT_APP_API_URL}/token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: loginForm.email,
        password: loginForm.password,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          setBadLogin(true);
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        props.setToken(data.access_token);
      })
      .then(() => {
        setLoading(true);
        navigate("/profile");
      })
      .catch((error) => {
        setBadLogin(true);
        console.error("Error:", error);
      })
      .finally(() => {
        setloginForm({
          email: "",
          password: "",
        });
      });

    event.preventDefault();
  }

  function handleChange(event) {
    const { value, name } = event.target;
    setloginForm((prevNote) => ({
      ...prevNote,
      [name]: value,
    }));
  }

  return (
    <Fragment>
      {loading ? (
        <div className="loader-container">
          <img src="./loader.gif" className="loader" />
        </div>
      ) : (
        <div className="login-page">
          <h1 className="login-top-title">Espace animateur</h1>
          <div className="login-container">
            <h1 className="login-title">Connectez-vous</h1>
            <form className="login-form">
              <input
                onChange={handleChange}
                type="email"
                text={loginForm.email}
                name="email"
                placeholder="Email"
                value={loginForm.email}
                className="form-input-container"
              />
              <input
                onChange={handleChange}
                type="password"
                text={loginForm.password}
                name="password"
                placeholder="Mot de passe"
                value={loginForm.password}
                className="form-input-container"
              />
              {badLogin && (
                <div className="bad-login-div">
                  Mot de passe ou email incorect
                </div>
              )}
              <button className="orange-button" onClick={logMeIn}>
                Se connecter
              </button>
            </form>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default Login;
