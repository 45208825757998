import React, { useState } from 'react';

const FormReport = ({gameId}) => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('not_send');
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Effectuer la requête POST vers l'API Flask
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/game/${gameId}/send_game_report`, 
        {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });
        if (response.ok) {
          setLoading(false);
          setStatus('success');
        } else {
          setLoading(false);
          setStatus('fail');
        }
      // Gérer la réponse du serveur, par exemple, afficher un message de succès
    } catch (error) {
      // Gérer les erreurs
    }
  };

  return (
    loading? ( <div className="loader-container">
    <img src="/loader.gif" className="loader" alt="Chargement..." />
  </div>): (
    status=='fail'? (<div className='final-form-result'>Nous avons enregistré votre participation ! Le rapport vous sera envoyé plus tard.</div>) 
    : status=='success' ? (<div className='final-form-result'>Merci à vous ! Le rapport vous a été envoyé par mail !</div>) :
    (<form onSubmit={handleSubmit}>
      <div className='final-form-container'>
        <input type="text" name="firstName" placeholder='Prénom' className='final-form-input' onChange={handleInputChange} />
        <input type="text" name="lastName" placeholder='Nom' className='final-form-input' onChange={handleInputChange} />
        <input type="email" name="email" placeholder='Email' className='final-form-input' onChange={handleInputChange} />
              {/* Nouveau champ pour l'année de naissance */}
        <input type="number" name="birthYear" placeholder='Année de naissance' className='final-form-input' onChange={handleInputChange} />

        <select name="employmentStatus" className='final-form-input' onChange={handleInputChange}>
          <option value="">Sélectionnez votre statut</option>
          <option value="etudiant">Étudiant</option>
          <option value="recherche_emploi">En recherche d'emploi</option>
          <option value="salarie">Salarié</option>
          <option value="retraite">Retraité</option>
          <option value="retraite">Élu</option>
          <option value="retraite">Dirigeant</option>
          <option value="autre">Autre</option>
        </select>

        <select name="activityDomain" className='final-form-input' onChange={handleInputChange}>
          <option value="">Sélectionnez votre domaine d'activité</option>
          <option value="environnement">Environnement</option>
          <option value="energie">Énergie</option>
          <option value="informatique">Informatique</option>
          <option value="batiment">Bâtiment</option>
          <option value="agroalimentaire">Agroalimentaire</option>
          <option value="banque_assurance">Banque / Assurance</option>
          <option value="industrie">Industrie</option>
          <option value="btp">BTP</option>
          <option value="chimie">Chimie</option>
          <option value="commerce_distribution">Commerce / Distribution</option>
          <option value="edition_communication_multimedia">Édition / Communication / Multimédia</option>
          <option value="conseil">Conseil</option>
          <option value="services_aux_entreprises">Services aux entreprises</option>
          <option value="textile">Textile</option>
          <option value="logistique">Logistique</option>
          <option value="tourisme">Tourisme</option>
          <option value="administration_publique">Administration publique</option>
          <option value="autre">Autre</option>
        </select>

        <button type="submit" className='blue-button'>Recevoir mon rapport</button>
      </div>
    </form>)
  )
  );
};

export default FormReport;
