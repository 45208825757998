import React, { useState, useEffect } from "react";
import '../index.css';
import "../App.css"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useParams } from "react-router-dom";
import { faRankingStar, faDroplet } from '@fortawesome/free-solid-svg-icons';

const GameSummary = ({ game_data }) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Simulation du chargement des données (remplacez cela par la logique réelle de récupération des données)
    const fetchData = async () => {
      // Remplacez setTimeout par votre logique réelle de récupération des données
      setTimeout(() => {
        setLoading(false);
      }, 5000); // 2000 millisecondes (2 secondes) pour la simulation
    };

    fetchData();
  }, []); // Exécution de l'effet une seule fois lorsque le composant est monté

  if (loading) {
    // Affichage du chargeur pendant le chargement des données
    return (
      <div className="loader-container">
        <img src="/loader.gif" className="loader" alt="Chargement..." />
      </div>
    );
  }

  // Assurez-vous que game_data est une liste non vide
  if (!game_data || game_data.length === 0) {
    return <div>Aucune donnée de jeu disponible</div>;
  }

  // Extraire les données du podium, du pire joueur et du joueur actuel
  const determinants = {
    'consommateurs': 'Les ',
    'dreal': 'La ',
    'energie': 'L\'',
    'office français de la biodiversité': 'L\'',
    'élus locaux': 'Les ',
    'chambre de commerce et d\'industrie': 'La ',
    'chambre d\'agriculture': 'La ',
    'commerces locaux': 'Les ',
    'industrie': 'L\'',
    'agence de l\'eau': 'L\'',
    'préfet': 'Le ',
    'client': 'Le ',
    // Ajoutez d'autres entrées au besoin
  };
  const verbes = {
    'consommateurs': ' ont ',
    'dreal': ' a ',
    'energie': 'L\'',
    'office français de la biodiversité': 'a',
    'élus locaux': 'ont',
    'chambre de commerce et d\'industrie': 'a',
    'chambre d\'agriculture': 'a',
    'commerces locaux': 'ont',
    'industrie': 'a',
    'agence de l\'eau': 'a',
    'préfet': 'a',
    'client': 'a',
    // Ajoutez d'autres entrées au besoin
  };
  const podium = game_data.best_players;
  const pireJoueur = game_data.worst_player;
  const stats = game_data.stat_game; // Modifiez ceci en fonction de la structure réelle des données
  const numberOfDecisions = game_data.number_of_decisions;
  const lowerCasePireJoueur = pireJoueur.toLowerCase();
  const determinant = determinants[lowerCasePireJoueur] || '';
  const verbe = verbes[lowerCasePireJoueur]

  const finishGame = async () => {
    navigate("/");
  };

  return (
    <div className="game-summary-container">
      <h1 className="game-summary-title">Rapport de fin de partie</h1>

      <h2 className="game-summary-subtitle">Les acteurs qui se sont démarqués :</h2>

      {/* Afficher le podium */}
      <div className="game-summary-grouped-container">
        <div className="game-summary-subcontainer-right">
          <FontAwesomeIcon icon={faRankingStar} className="game-summary-icon"/>

          <ol className="game-summary-list">
            {podium.map((player, index) => (
              <li key={index} style={{ fontSize: '1em', margin: '10px 0', color: 'var(--hydros-blue)', textAlign: 'center' }}>
                <span style={{ marginRight: '10px', fontSize: '1em' }}>{index + 1} -</span>
                {player}
              </li>
            ))}
          </ol>
        </div>
        <div className="game-summary-subcontainer-left">
          <FontAwesomeIcon icon={faDroplet} className="game-summary-blue-icon" />
          <p className="game-summary-text">{determinant} <span className="game-summary-bold-text">{pireJoueur}</span> <br /> {verbe} fait le plus d'efforts</p>
        </div>
      </div>

      {/* Afficher les statistiques du joueur actuel */}
      <div>
        <h2 className="game-summary-subtitle">Vos résultats d'équipe :</h2>
        <div className="game-summary-grouped-container">
        <div className="game-summary-subcontainer-right">
            <div className="game-summary-number">{numberOfDecisions}</div>
            <div className="game-summary-text">décisions prises<br />au cours de la partie</div>
          </div>
          <div className="game-summary-subcontainer-bottom-left">
            <div className="game-summary-number">{stats}%</div>
            <div className="game-summary-text">des autres parties sont<br />moins bonnes</div>
          </div>
        </div>
      </div>
      <div className="separator"></div>
      <button className="blue-button" onClick={finishGame}> Retour à l'accueil </button>
    </div>

  );
};

export default GameSummary;
