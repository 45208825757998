import {
  faChevronDown,
  faCircleChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

const ActorsBudgets = ({ actorsBudgets, handleNext, consequences, round }) => {
  const [happeningType, setHappeningType] = useState(null);

  const getColor = (name) => {
    switch (name) {
      case "onb":
        return "green-bg";
      case "dreal":
        return "blue-bg";
      case "edf":
        return "yellow-bg";
      case "consumers":
        return "red-bg";
      case "elus-locaux":
        return "white-bg";
      case "agriculture":
        return "orange-bg";
      case "petit-commerce":
        return "pink-bg";
      case "grosse-industrie":
        return "purple-bg";
      case "industry":
        return "purple-bg";
      case "agence-eau":
        return "gray-bg";
      default:
        return "default-bg";
    }
  };

  useEffect(() => {
    if (round === 3) {
      const happening = consequences.find(
        (consequence) => consequence.is_happening
      );
      if (happening) {
        setHappeningType(happening.happening_category);
      }
    }
  }, [JSON.stringify(consequences)]);
  return (
    <div className="content">
      <div className="vertical-container">
        <h3 className="recap-text no-bold">
          Voici les voix dont disposent chaque acteur pour ce tour :{" "}
        </h3>
        <div className="budgets-container">
          {actorsBudgets.map((actorBudget) => (
            <div className="budget-container">
              <p className="actor-name">{actorBudget.name}</p>
              <p className={"actor-budget " + getColor(actorBudget.actor_id)}>
                {actorBudget.budget}
                {actorBudget.actor_id === "elus-locaux" ||
                actorBudget.actor_id === "edf" ? (
                  <img className="currency" src="/vote-blue4.png" />
                ) : (
                  <img className="currency" src="/vote-white4.png" />
                )}
              </p>
            </div>
          ))}
        </div>
        {happeningType &&
          (happeningType === "popularity" ? (
            <div className="happening-text">
              Les restrictions d'eau ont suscité un mécontentement croissant
              parmi les citoyens. Jardins asséchés, lavages limités : la
              frustration grandit face aux contraintes imposées pour préserver
              cette ressource vitale. Des manifestations ont éclatées, la
              population demandant que tous participent à l'effort collectif et
              que les mesures ne les ciblent pas seuls. Sensibilisation et
              solutions collaboratives seront essentielles pour trouver un
              équilibre durable.
              <br />
              <br />
              Face à ces revendications le préfet décide de redistribuer une
              voix de chacun des trois acteurs les plus puissants aux
              consommateurs.
            </div>
          ) : happeningType === "biodiversity" ? (
            <div className="happening-text">
              Les mesures précédentes pour contrer la crise de biodiversité ont
              eu des conséquences néfastes sur les écosystèmes fragiles. Des
              activistes reprochent un laxisme flagrant des dirigeants, face à
              la perte de diversité des espèces, la destruction des habitats et
              la dégradation de l'environnement. Les citoyens exigent des
              actions concrètes et une prise de responsabilité collective pour
              préserver notre précieux héritage naturel. Sensibilisation et
              décisions politiques audacieuses seront essentielles pour protéger
              notre planète.
              <br />
              <br />
              Face à ces revendications le préfet décide de redistribuer une
              voix de chacun des trois acteurs les plus puissants à l’OFB.
            </div>
          ) : happeningType === "carbon_economy" && actorsBudgets.length > 6 ? (
            <div className="happening-text">
              Une crise énergétique de grande ampleur frappe actuellement la
              région, qui fait face à une sécheresse sans précédents. Les cours
              d’eau sont au plus bas et plusieurs centrales nucléaires ont du
              ralentir voir même stopper leur activité à cause du risque de ne
              pas pouvoir assurer leur refroidissement. Des mesures ont été
              prises en urgence pour inciter à la diminution de la consommation
              énergétique. Une centrale à charbon va être relancée pour pallier
              à la demande.
              <br />
              <br />
              Face à cette situation le préfet décide de redistribuer une voix
              de chacun des trois acteurs les plus puissants au secteur de
              l’énergie.
            </div>
          ) : happeningType === "economy" ? (
            <div className="happening-text">
              La crise économique actuelle a durement frappé la région suite aux
              mesures prises dans le cadre du plan eau, impactant le tourisme et
              certaines grandes industries locales. Cela met en péril l'emploi,
              les revenus et l'attractivité de la région, avec des licenciements
              massifs et des fermetures d'entreprises. Une approche concertée et
              des solutions innovantes seront nécessaires pour surmonter ces
              défis économiques majeurs et restaurer la vitalité régionale.
              <br />
              <br />
              Face à cette situation le préfet décide de redistribuer une voix
              de chacun des trois acteurs les plus puissants au secteur
              industriel.
            </div>
          ) : happeningType === "water_quality" ? (
            <div className="happening-text">
              Une crise de qualité de l'eau frappe la région, suite aux
              révélations de médias indépendants tels que Brut ou Mediapart sur
              des non-conformités aux normes établies. Les inquiétudes
              grandissent parmi les résidents, mettant en jeu la santé publique.
              Des actions décisives sont nécessaires pour rétablir la qualité de
              l'eau et regagner la confiance des citoyens dans leur
              approvisionnement en eau potable.
              <br />
              <br />
              Face à cette situation le préfet décide de redistribuer une voix
              de chacun des trois acteurs les plus puissants à la DREAL.
            </div>
          ) : (
            <div></div>
          ))}

        <FontAwesomeIcon
          className="next"
          icon={faChevronDown}
          onClick={handleNext}
        />
      </div>
    </div>
  );
};

export default ActorsBudgets;
