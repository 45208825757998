// Context.js
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckToSlot, faMedal, faTemperatureThreeQuarters, faGlobe  } from "@fortawesome/free-solid-svg-icons";

const Context = ({ status }) => {
    let title, description, iconEvent, householdsWithoutWater, context2, descriptionContext2, awarenessCampaign ;
  
    switch (status.roundNumber) {
      case 0:
        title = "Jeux Olympiques";
        description = "Les Jeux Olympiques ont eu lieu cette année.";
        iconEvent = faMedal;
        householdsWithoutWater = 35;
        context2 = 5;
        descriptionContext2 = "canicules observées";
        awarenessCampaign = 2;
        break;
      case 1:
        title = "COP 30 au Brésil";
        description = "COP 30 au Brésil";
        iconEvent = faGlobe;
        householdsWithoutWater = 41;
        context2 = "14.7°C";
        descriptionContext2 = (
            <div>année la plus chaude enregistrée<br />(en France métropolitaine, depuis 1947)</div>
            );
        awarenessCampaign = 5;
        break;
      case 2:
        title = "Élections Municipales";
        description = "Les élections municipales ont eu lieu cette année.";
        iconEvent = faCheckToSlot;
        householdsWithoutWater = 51;
        context2 = "6.5k";
        descriptionContext2 = (
            <div>décès estimés à cause<br />des sécheresses (en France)</div>
            );
        awarenessCampaign = 20;
        break;
      case 3:
        title = "Élections Présidentielles";
        description = "Les élections présidentielles ont eu lieu cette année.";
        iconEvent = faCheckToSlot;
        householdsWithoutWater = 43;
        context2 = 5;
        descriptionContext2 = "canicules observées";
        awarenessCampaign = 100;
        break;
      case 4:
        title = "Dépassement des 1,4°C";
        description = "Le seuil de 1,4° de réchauffement a été dépassé à l'échelle mondiale.";
        iconEvent = faTemperatureThreeQuarters ;
        householdsWithoutWater = 37;
        context2 = 36;
        descriptionContext2 = (
            <div>jours sans pluie<br />(en France métropolitaine)</div>
            );
        awarenessCampaign = 500;
        break;
      default:
        title = "Événement inconnu";
        description = "Aucun événement spécifique pour cette année.";
        iconEvent = faCheckToSlot;
        householdsWithoutWater = 35;
        context2 = 36;
        descriptionContext2 = (
            <div>jours sans pluie<br />(en France métropolitaine)</div>
            );
        awarenessCampaign = 2;
    }
  
    return (
      <div>
        <div className="chart-title" style={{ textAlign: 'center', marginTop: '-40px', marginLeft: '250px' }}>Contexte</div>
        <div style={{ textAlign: 'center', marginTop:'50px' }}>
          <div style={{ display: 'inline-block', margin: '0 15px' }}>
            <p style={{ fontSize: '3em', fontWeight: 'bold', margin: '0', color: 'var(--hydros-orange)' }}>
              {householdsWithoutWater}k
            </p>
            <p style={{ fontSize: '1em', margin: '0', color: 'var(--hydros-blue)' }}>personnes ont été privées d'eau</p>
          </div>
          <div style={{ display: 'inline-block', margin: '0 15px' }}>
            <p style={{ fontSize: '3em', fontWeight: 'bold', margin: '0', color: 'var(--hydros-orange)' }}>
              <FontAwesomeIcon icon={iconEvent} />
            </p>
            <p style={{ fontSize: '1em', margin: '0' , color: 'var(--hydros-blue)' }}>{title}</p>
          </div>
        </div>
        <div style={{ textAlign: 'center', marginBottom:'50px' }}>
          <div style={{ display: 'inline-block', margin: '0 15px' }}>
            <p style={{ fontSize: '3em', fontWeight: 'bold', margin: '0', color: 'var(--hydros-orange)' }}>
              {context2}
            </p>
            <p style={{ fontSize: '1em', margin: '0', color: 'var(--hydros-blue)' }}>
              {descriptionContext2}
            </p>
          </div>
          <div style={{ display: 'inline-block', margin: '0 15px' }}>
            <p style={{ fontSize: '3em', fontWeight: 'bold', margin: '0', color: 'var(--hydros-orange)' }}>
              {awarenessCampaign}k
            </p>
            <p style={{ fontSize: '1em', margin: '0' , color: 'var(--hydros-blue)' }}>
              personnes sensibilisées à <br />l'Atelier Compte-Gouttes
            </p>
          </div>
        </div>
      </div>
    );
  };

export default Context;