import {
  faChevronDown,
  faCircleChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, Fragment } from "react";

const Consequences = ({ consequences, hideConsequences }) => {
  const [index, setIndex] = useState(0);

  const nextConsequence = () => {
    if (index + 1 === consequences.length) {
      hideConsequences();
    } else {
      setIndex(index + 1);
    }
  };

  useEffect(() => {
    if (consequences.length === 0) {
      hideConsequences();
    }
  }, [JSON.stringify(consequences)]);

  return (
    <div className="consequence-section">
      <div className="consequences">
        {consequences.map((consequence, i) => (
          <div
            key={consequence._id}
            className={
              index === i ? "consequence visible" : "consequence hidden"
            }
          >
            {consequence.type ? (
              consequence.type === "picture" ? (
                <div>
                  <img
                    className="consequence-image"
                    src={consequence.media_url}
                    alt="consequence"
                  />
                </div>
              ) : (
                <video controls className="video-display">
                  <source src={consequence.media_url} type="video/mp4" />
                  Sorry, your browser doesn't support videos.
                </video>
              )
            ) : (
              <div className="consequence-container">
                <h3>{consequence.title}</h3>
                <p>{consequence.description}</p>
              </div>
            )}
          </div>
        ))}
      </div>
      <FontAwesomeIcon
        className="next"
        icon={faChevronDown}
        onClick={nextConsequence}
      />
    </div>
  );
};

export default Consequences;
