import React, { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import CustomSelect from "./CustomSelect";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import "dayjs/locale/fr";

const GameConfigPage = (props) => {
  const [loading, setLoading] = useState(false);
  const [numberPlayer, setNumberPlayer] = useState(6);
  const [additionalHappening, setAdditionalHappening] = useState(false);
  const [displayIndicators, setDisplayIndicators] = useState(false);
  const [workshopName, setWorkshopName] = useState("");
  const [workshopType, setWorkshopType] = useState("grand_public");
  const [bassin, setBassin] = useState("rhone");
  const [addTicketing, setAddTicketing] = useState(false);
  const [ticketPrice, setTicketPrice] = useState(10);
  const [workshopDescription, setWorkshopDescription] = useState("");
  const [startDate, setStartDate] = useState(dayjs(new Date()));
  const [endDate, setEndDate] = useState(dayjs(new Date()));
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [includeClient, setIncludeClient] = useState("");

  const options = [
    { label: "4 joueurs", value: 4 },
    { label: "5 joueurs", value: 5 },
    { label: "6 joueurs", value: 6 },
    { label: "7 joueurs", value: 7 },
    { label: "8 joueurs", value: 8 },
    { label: "9 joueurs", value: 9 },
    { label: "10 joueurs", value: 10 },
  ];

  const optionsBassins = [
    { label: "Rhône-Méditerranée", value: 'rhone' },
    { label: "Adour-Garonne", value: 'adour' },
    { label: "Loire-Bretagne", value: 'loire' },
    { label: "Rhine-Meuse", value: 'rhin' },
    { label: "Seine-Normandie", value: 'seine' },
    { label: "Artois-Picardie", value: 'artois' },
  ];

  const workshopTypes = [
    { label: "Grand public", value: "grand_public" },
    { label: "Entreprise", value: "entreprise" },
    { label: "Association", value: "association" },
    { label: "Privé", value: "prive" },
    { label: "Collectivité", value: "collectivite" },
    { label: "Enseignement supérieur", value: "enseignement_superieur" },
    { label: "Test", value: "test" },
  ];
  //const [allOptions, setAllOptions] = useState(options)

  const navigate = useNavigate();

  const handleTitleChange = (event) => {
    setWorkshopName(event.target.value);
  };

  const handleActorChange = (selectedOption) => {
    setNumberPlayer(selectedOption.value);
  };

  const handleWorkshopTypeChange = (selectedOption) => {
    setWorkshopType(selectedOption.value);
  };

  const handleBassinChange = (selectedOption) => {
    setBassin(selectedOption.value);
  };

  const handleAdditionalHappeningChange = () => {
    setAdditionalHappening(!additionalHappening);
  };

  const handleDisplayIndicatorsChange = () => {
    setDisplayIndicators(!displayIndicators);
  };

  const handleAddTicketingChange = () => {
    setAddTicketing(!addTicketing);
  };

  const createTicketing = async () => {
    setLoading(true);
    const dataReceive = {
      number_player: numberPlayer,
      show_happening: additionalHappening,
      show_additional_score: displayIndicators,
      workshop_name: workshopName,
      status: "tickets_created",
      type: workshopType,
      add_ticketing: addTicketing,
      description: workshopDescription,
      start_date: startDate.toISOString().split(".")[0] + "Z",
      end_date: endDate.toISOString().split(".")[0] + "Z",
      ticket_price: ticketPrice,
      address: address,
      city: city,
      postal_code: postalCode,
      include_player: includeClient, 
      bassin: bassin,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/create_ticketing`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + props.token,
          },
          body: JSON.stringify(dataReceive),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      navigate("/profile");
    } catch (error) {
      // Gérez les erreurs ici
      setLoading(false);
      console.error("Error posting data:", error);
    }
  };

  const handleSubmit = async (startNow) => {
    setLoading(true);
    const dataReceive = {
      number_player: numberPlayer,
      show_happening: additionalHappening,
      show_additional_score: displayIndicators,
      workshop_name: workshopName,
      status: "not started",
      type: workshopType,
      include_client: includeClient, 
      bassin: bassin,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/newgame`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + props.token,
        },
        body: JSON.stringify(dataReceive),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      console.log("response", responseData);
      const gameId = responseData.game_id;

      // Redirigez vers la page du jeu avec le gameId
      if (startNow) {
        navigate("/intro/" + gameId + "?bassin="+bassin);
      } else {
        navigate("/profile");
      }
    } catch (error) {
      // Gérez les erreurs ici
      setLoading(false);
      console.error("Error posting data:", error);
    }
  };

  return (
    <Fragment>
      {loading ? (
        <div className="loader-container">
          <img src="./loader.gif" className="loader" />
        </div>
      ) : (
        <div className="game-config">
          <div className="header_selection_container">
            <h1 className="home-title">Configuration du jeu</h1>
            <div className="form-group">
              <div className="num_actor_text">
                Vous avez selectionné {numberPlayer} acteurs.
              </div>
            </div>
          </div>
          <div className="form-container">
            <div className="additional_game_selection_container">
              <div className="input-label">Nom de votre atelier : </div>
              <input
                placeholder="Atelier de démonstration"
                value={workshopName}
                onChange={handleTitleChange}
                className="input"
              />
            </div>

            <div className="additional_game_selection_container">
              <div className="input-label">
                {addTicketing && workshopType === "grand_public"
                  ? "Nombre de participants maximum"
                  : "Nombre de participants :"}
              </div>
              <CustomSelect
                options={options}
                onSelect={handleActorChange}
                defaultValue={{ label: "6 joueurs", value: 6 }}
              />
            </div>
            {numberPlayer !== 4 && (
              <div className="additional_game_selection_container">
                <div className="input-label">Dont le client</div>
                <CustomSelect
                  options={[
                    { label: "Oui", value: true },
                    { label: "Non", value: false },
                  ]}
                  onSelect={(selectedOption) => setIncludeClient(selectedOption.value)}
                  defaultValue={{ label: "Non", value: false }}
                />
              </div>
            )}

            <div className="additional_game_selection_container">
              <div className="input-label">Type de public : </div>
              <CustomSelect
                options={workshopTypes}
                onSelect={handleWorkshopTypeChange}
                defaultValue={{ label: "Grand public", value: "grand_public" }}
              />
            </div>

            <div className="additional_game_selection_container">
              <div className="input-label">Bassin versant : </div>
              <CustomSelect
                options={ optionsBassins}
                onSelect={handleBassinChange}
                defaultValue={{ label: "Rhône-Méditerranée", value: 'rhone' }}
              />
            </div>

            <div className="additional_game_selection_container">
              <div className="form-group">
                <div className="selector_container">
                  <div className="name">Afficher les scores : </div>
                  <label className="switch-button">
                    <input
                      type="checkbox"
                      checked={displayIndicators}
                      onChange={handleDisplayIndicatorsChange}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div className="form-group">
                <div className="selector_container">
                  <div className="name">Happening supplémentaire :</div>
                  <label className="switch-button">
                    <input
                      type="checkbox"
                      checked={additionalHappening}
                      onChange={handleAdditionalHappeningChange}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
            {workshopType === "grand_public" && (
              <div className="additional_game_selection_container">
                <div className="form-group">
                  <div className="selector_container">
                    <div className="name">Ajouter une billeterie : </div>
                    <label className="switch-button">
                      <input
                        type="checkbox"
                        checked={addTicketing}
                        onChange={handleAddTicketingChange}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>
            )}

            {addTicketing && workshopType === "grand_public" && (
              <div className="additional_game_selection_container">
                <div className="ticketing-explanation">
                  En remplissant ce formulaire une billeterie sera créée sur
                  Eventbrite, vous pourrez la partager aux participants pour
                  qu'ils s'inscrivent.
                </div>
                <div className="datepicker-container">
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="fr"
                  >
                    <DemoContainer components={["DateTimePicker"]}>
                      <DateTimePicker
                        label="Date de début"
                        ampm={false}
                        value={startDate}
                        onChange={(newValue) => setStartDate(newValue)}
                      />
                    </DemoContainer>
                  </LocalizationProvider>

                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="fr"
                  >
                    <DemoContainer components={["DateTimePicker"]}>
                      <DateTimePicker
                        label="Date de fin"
                        ampm={false}
                        value={endDate}
                        onChange={(newValue) => setEndDate(newValue)}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
                <div className="form-group">
                  <div className="address-container">
                    <div className="name">Lieu de l'atelier : </div>
                    <input
                      className="workshop-address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      placeholder="Adresse du lieu d'animation de l'atelier"
                    />
                    <input
                      className="workshop-postal-code"
                      value={postalCode}
                      onChange={(e) => setPostalCode(e.target.value)}
                      placeholder="Code postal"
                    />
                    <input
                      className="workshop-city"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      placeholder="Ville"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="description-container">
                    <div className="name">Description de l'atelier : </div>
                    <textarea
                      className="workshop-description"
                      value={workshopDescription}
                      onChange={(e) => setWorkshopDescription(e.target.value)}
                      rows="3"
                      placeholder="Description de l'événement qui sera affichée sur la billeterie"
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <div className="ticket-price-container">
                      <div className="name">Prix d'un billet : </div>
                      <div>
                        <input
                          type="number"
                          className="ticket-price"
                          value={ticketPrice}
                          onChange={(e) => setTicketPrice(e.target.value)}
                        />
                        €
                      </div>
                    </div>
                    <div className="ticket-price-tooltip">
                      Vous toucherez environ{" "}
                      <b>
                        {String(
                          Math.round(
                            (ticketPrice * 0.85 - 15 / numberPlayer) * 100
                          ) / 100
                        ).replace(".", ",")}{" "}
                        €
                      </b>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {addTicketing && workshopType === "grand_public" ? (
              <div className="form-group center_element">
                <button className="blue-button" onClick={createTicketing}>
                  Créer la billeterie et lancer le jeu plus tard
                </button>
              </div>
            ) : (
              <div className="form-group center_element">
                <button
                  className="blue-button"
                  onClick={() => handleSubmit(true)}
                >
                  Créer le jeu et jouer maintenant
                </button>
                <button
                  className="blue-button"
                  onClick={() => handleSubmit(false)}
                >
                  Créer le jeu pour plus tard
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default GameConfigPage;
